<template>
    <div class="container">
        <div class="model" v-if="show">
            <div class="model-box">
               <p>请确认微信支付是否已完成</p>
                <p @click="completePay">已完成支付</p>
                <p @click="payError">支付遇到问题，重新支付</p>
            </div>
        </div> 
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js';

export default {
    data(){
        return{
            title:this.$route.meta.title,
            show:true
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
    methods: {
        async pay(){
          let args = {
            appId:"webhaojingchouh5",
            artId: "101",
            bodyContent: "付费虚拟币",
            openid: "",
            payId: 1,
            price: 0.01,
            titles: "支付金蛋",
            transactionType: "JSAPI"
          };
          const res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            console.log("支付金蛋内容",res);
            let url;
            if(res.data && res.data.statusCode == 200){
                url = res.data.htmlResult
                url = url.match(/(\https.*)(?=\&redirect)/g)
                window.location.href = url
                this.show = true
            }else{
                console.log(res.data.responseDescription);
            }
        },
        completePay(){
            this.show = false
        },
        payError(){
            this.pay()
        }
    },
}
</script>
<style lang="less">
    .container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .model{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background: rgba(0,0,0,0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            .model-box{
                width: 260px;
                height: 120px;
                background-color: #fff;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                p{
                    width: 100%;
                    height: 88px;
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    border-bottom: 1px solid #ddd;
                    text-align: center;
                    line-height: 36px;
                    &:nth-child(2){
                        color: red;
                    }
                    &:last-child{
                        border-bottom: none;
                        color: rgb(142, 138, 138);
                    }
                }
            }
        }
    }
</style>